<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="600"
    >
      <div class="bg-white poppins">
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">Edit Item</h3>
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="toggleModal"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
        </div>
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <!--begin::Body-->
          <div>
            <v-radio-group class="mt-0" v-model="category_type">
              <div class="d-flex">
                <v-radio color="indigo lighten-2" value="category" class="mr-4">
                  <template v-slot:label>
                    <div class="mt-2">Parent category</div>
                  </template>
                </v-radio>
                <v-radio color="indigo lighten-2" value="sub" class="mt-n2">
                  <template v-slot:label>
                    <div class="mt-2">Sub-category</div>
                  </template>
                </v-radio>
              </div>
            </v-radio-group>
          </div>
          <div v-if="category_type === 'sub'" class="h-75px">
            <v-select
              dense
              outlined
              clearable
              v-model="formData.parent_id"
              label="Parent category"
              item-text="text"
              item-value="index"
              :items="parent_categories"
              :error-messages="parentCategoryErrors"
              @input="$v.formData.parent_id.$touch()"
              @blur="$v.formData.parent_id.$touch()"
            ></v-select>
          </div>
          <div class="h-75px">
            <v-text-field
              v-model="formData.name"
              label="Name"
              clearable
              outlined
              dense
              :error-messages="nameErrors"
              @input="$v.formData.name.$touch()"
              @blur="$v.formData.name.$touch()"
            ></v-text-field>
          </div>
          <div class="h-75px">
            <v-text-field
              v-model="formData.description"
              label="Description"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <!--end::Body-->
          <div class="my-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="resetEditForm"
            >
              Reset
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitEditForm"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

export default {
  name: "EditItem",
  mixins: [validationMixin],
  validations: {
    formData: {
      name: { required },
      parent_id: {
        required: requiredIf(function () {
          return this.category_type === "sub";
        }),
      },
    },
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      parent_id: null,
      id: null,
      name: null,
      description: null,
    },
    category_type: "category",
    parent_categories: [],
  }),
  methods: {
    setSkus(item) {
      this.formData.skus = [...item];
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;

      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },

    submitEditForm() {
      if (this.category_type === "category") {
        this.formData = {
          ...this.formData,
          parent_id: null,
          type: "parent_category",
        };
      } else if (this.category_type === "sub") {
        this.formData = {
          ...this.formData,
          type: "sub_category",
        };
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.pageLoader(true);
      let data = this.formData;
      ApiService.post("/order_management/groups/update", data)
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: `SKU Group data has been updated`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetEditForm() {
      this.$v.$reset();
      this.formData.group_id = null;
      this.formData.name = null;
      this.formData.description = null;
    },
    toggleModal() {
      if (this.dialog) this.resetEditForm();
      else {
        this.getItemData();
      }
      this.dialog = !this.dialog;
    },
    getItemData() {
      this.$store.commit(SET_PAGE_LOADING, true);
      return new Promise(() => {
        ApiService.post(`/order_management/groups/edit`, {
          id: this.itemForAction.id,
        })
          .then(({ data }) => {
            this.formData = {
              ...data.category,
            };
            this.parent_categories = data.categories;
            if (data.category.type === "sub_category") {
              this.category_type = "sub";
            } else {
              this.category_type = "category";
            }
          })
          .finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      });
    },
  },
  computed: {
    itemForAction: function () {
      return this.$store.getters.getItemForAction;
    },
    nameErrors() {
      return this.handleFormValidation("name", this);
    },
    parentCategoryErrors: function () {
      return this.handleFormValidation("parent_id", this);
    },
  },
};
</script>
